<!--
File: RoadsDropdown.vue
Description: show the dropdown combo with the roads list.
-->
<template>
  <md-field>
    <label for="road_id">{{ label }}</label>
    <md-select v-model='road_id' name="road_id" id="road_id" :disabled="disabled" :required="is_required"
      @md-selected='onRoadChange' size="10">
      <md-option v-for='item in roadsList' :key='item.id' :value='item.id'>
        {{ item.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'roads-dropdown',

    props: {
      label: { default: "", type: String },
      initial_value: { default: null, type: Number },
      region_id: { default: null, type: Number },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        road_id: this.initial_value,
        roadListLoaded: false
      }
    },

    mounted() {
      //this.road_id = this.initial_value
    },

    methods: {
      onRoadChange() {
        /*const road_desc = (!this.road_id || this.road_id == null) ? '' :
          this.roadsList.find((element) => element.road_id == this.road_id)?.road_description
          */
        const road_desc = !this.road_id ? '' : this.roadsList.find((el) => el.id == this.road_id)?.description
        this.$emit('input', this.road_id, road_desc)
      },
    },

    computed: {
      roadsList() {
        return this.$store.state.Dropdowns.roads;
      },
    },

    watch: {
      region_id(newValue, oldValue) {
        this.roadListLoaded = false
        //this.road_id = null
        this.$store.dispatch('GET_ROADS_DROPDOWN', { region_id: newValue, show_all: !this.is_required }).then(() => {
          this.roadListLoaded = true
          if (this.initial_value && !this.road_id) this.road_id = this.initial_value
        })
      },

      initial_value(newValue, oldValue) {
        if (newValue !== oldValue && this.roadListLoaded) {
          this.road_id = newValue
        }
      }
    }
  }
</script>